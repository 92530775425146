import { ApplicationActionEnum, TApplicationActions } from './application-actions'
import {
  CONTROL_PERSON_CANDIDATE_ID,
  IApplicationState,
  initialApplicationState,
  initialOwnerState,
  PRIMARY_OWNER_ID,
} from './application-state'

export const applicationReducer = (
  state: IApplicationState = initialApplicationState,
  action: TApplicationActions,
): IApplicationState => {
  switch (action.type) {
    case ApplicationActionEnum.SET_BUSINESS_ID:
      return {
        ...state,
        businessId: action.payload,
      }
    case ApplicationActionEnum.SET_APPLICATION_ID:
      return {
        ...state,
        applicationId: action.payload,
      }
    case ApplicationActionEnum.SET_USER_ID:
      return {
        ...state,
        userId: action.payload,
      }
    case ApplicationActionEnum.SET_TERMS_AND_CONDITIONS_ACCEPTED:
      return {
        ...state,
        hasAcceptedTermsAndConditions: action.payload,
      }
    case ApplicationActionEnum.SET_IS_PRIMARY_OWNER:
      return {
        ...state,
        isPrimaryOwner: action.payload,
      }
    case ApplicationActionEnum.SET_IS_REFERRAL_HYDRATE_DATA:
      return {
        ...state,
        referralDataWasHydrated: action.payload,
      }
    case ApplicationActionEnum.SET_BUSINESS_TYPE:
      return {
        ...state,
        businessType: action.payload,
      }
    case ApplicationActionEnum.SET_BUSINESS_NAME:
      return {
        ...state,
        businessName: action.payload,
      }
    case ApplicationActionEnum.SET_PROHIBITED_INDUSTRIES_CHECK:
      return {
        ...state,
        prohibitedIndustriesCheck: action.payload,
      }
    case ApplicationActionEnum.SET_SHOULD_USE_BUSINESS_ADDRESS_AS_OWNER_ADDRESS:
      return {
        ...state,
        selectedSameBusinessAndHomeAddressCheckbox: action.payload,
      }
    case ApplicationActionEnum.SET_TRADE_NAME:
      return {
        ...state,
        tradeName: action.payload,
      }
    case ApplicationActionEnum.SET_BUSINESS_DESCRIPTION:
      return {
        ...state,
        businessDescription: action.payload,
      }
    case ApplicationActionEnum.SET_FULL_TIME_EMPLOYEES:
      return {
        ...state,
        fullTimeEmployees: action.payload,
      }
    case ApplicationActionEnum.SET_BUSINESS_INDUSTRY:
      return {
        ...state,
        businessIndustry: action.payload,
      }
    case ApplicationActionEnum.SET_BUSINESS_PHONE_NUMBER:
      return {
        ...state,
        businessPhoneNumber: action.payload,
      }
    case ApplicationActionEnum.SET_BUSINESS_ADDRESS:
      return {
        ...state,
        businessAddress: {
          ...state.businessAddress,
          ...action.payload,
        },
      }
    case ApplicationActionEnum.SET_BUSINESS_SOCIAL_MEDIA_LINK:
      return {
        ...state,
        socialMedia: {
          ...state.socialMedia,
          ...action.payload,
        },
      }
    case ApplicationActionEnum.SET_BUSINESS_REVENUE:
      return {
        ...state,
        businessRevenue: action.payload,
      }
    case ApplicationActionEnum.SET_IS_US_CITIZEN:
      return {
        ...state,
        isUSCitizen: action.payload,
      }
    case ApplicationActionEnum.SET_HAS_STARTED_APPLICATION:
      return {
        ...state,
        hasStartedApplication: action.payload,
      }
    case ApplicationActionEnum.SET_HAS_REGISTERED_BUSINESS_NAME:
      return {
        ...state,
        hasRegisteredBusinessName: action.payload,
      }
    case ApplicationActionEnum.UPDATE_OWNER: {
      const { updatedOwnerFields, ownerId } = action.payload
      // Check if the owner exists before updating
      const owner = state.owners[ownerId]
      if (!owner) {
        throw new Error(`Unable to update, owner with ID ${ownerId} does not exist`)
      }
      return {
        ...state,
        owners: {
          ...state.owners,
          [ownerId]: {
            ...owner,
            ...updatedOwnerFields,
          },
        },
      }
    }
    case ApplicationActionEnum.ADD_NEW_OWNER: {
      const primaryOwner = state.owners[PRIMARY_OWNER_ID]
      if (!primaryOwner) {
        throw new Error('Primary owner does not exist')
      }
      return {
        ...state,
        owners: {
          ...state.owners,
          [PRIMARY_OWNER_ID]: {
            ...primaryOwner,
          },
          [getRandomId()]: {
            ...initialOwnerState,
            isInviteCheckboxSelected: true,
          },
        },
      }
    }
    case ApplicationActionEnum.SET_SHOULD_INVITE_OWNER: {
      const { shouldSendInvite, ownerId } = action.payload
      // Double check the owner exists before inviting
      const owner = state.owners[ownerId]
      if (!owner) {
        throw new Error(`Unable to invite, owner with ID ${ownerId} does not exist`)
      }
      return {
        ...state,
        owners: {
          ...state.owners,
          [ownerId]: {
            ...owner,
            ...shouldSendInvite,
          },
        },
      }
    }

    case ApplicationActionEnum.DELETE_OWNER: {
      const ownerIdToDelete = action.payload
      // Ensure we're not deleting the primary owner
      if (ownerIdToDelete === PRIMARY_OWNER_ID) {
        throw new Error('Cannot delete the primary owner')
      }
      // Create a new owners object without the deleted owner, eslint doesn't like the destructuring
      // eslint-disable-next-line no-unused-vars
      const { [ownerIdToDelete]: _deletedOwner, ...newOwners } = state.owners
      return {
        ...state,
        owners: newOwners,
      }
    }
    case ApplicationActionEnum.SET_IS_CORP_WITH_MULTIPLE_OWNERS:
      return {
        ...state,
        isCorpWithMultipleOwners: action.payload,
        owners: action.payload
          ? state.owners // Keep existing owners if true
          : { [PRIMARY_OWNER_ID]: state.owners[PRIMARY_OWNER_ID] ?? initialOwnerState },
      }

    case ApplicationActionEnum.ADD_CONTROL_PERSON_CANDIDATE: {
      return {
        ...state,
        owners: {
          ...state.owners,
          [CONTROL_PERSON_CANDIDATE_ID]: { ...initialOwnerState, isInviteCheckboxSelected: true },
        },
        controlPersonId: CONTROL_PERSON_CANDIDATE_ID,
      }
    }
    case ApplicationActionEnum.SET_CONTROL_PERSON_ID:
      return {
        ...state,
        controlPersonId: action.payload,
      }
    case ApplicationActionEnum.SET_EIN:
      return {
        ...state,
        ein: action.payload,
      }
    case ApplicationActionEnum.SET_YEARS_AT_LOCATION:
      return {
        ...state,
        yearsAtLocation: action.payload,
      }
    case ApplicationActionEnum.SET_YEARS_IN_OPERATION:
      return {
        ...state,
        yearsInOperation: action.payload,
      }
    case ApplicationActionEnum.SET_HAS_CLICKED_DOCUMENTS_UPLOADED:
      return {
        ...state,
        hasClickedDocumentsUploaded: action.payload,
      }
    case ApplicationActionEnum.SET_SOCURE_DEVICE_SESSION_ID: {
      return {
        ...state,
        socureDeviceSessionId: action.payload,
      }
    }
    case ApplicationActionEnum.SET_REFERRAL_APPLICATION_DATA: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case ApplicationActionEnum.SET_PARTNER_INFORMATION: {
      return {
        ...state,
        partnerInformation: action.payload,
      }
    }
    case ApplicationActionEnum.SET_HAS_COMPLETED_TOP_UP_OPT_IN_PAGE: {
      return {
        ...state,
        hasCompletedTopUpOptInPage: action.payload,
      }
    }
    case ApplicationActionEnum.PATCH_ONLINE_PRESENCE: {
      return {
        ...state,
        onlinePresence: {
          ...state.onlinePresence,
          ...action.payload,
        },
      }
    }
    case ApplicationActionEnum.SET_EMAIL_VERIFIED: {
      return {
        ...state,
        emailVerified: action.payload,
      }
    }
    case ApplicationActionEnum.SET_HAS_APPLIED_PROMO_CODE: {
      return {
        ...state,
        hasAppliedPromoCode: action.payload,
      }
    }
    case ApplicationActionEnum.SET_SIGNUP_PROMO_CODE: {
      return {
        ...state,
        signupPromoCode: action.payload,
      }
    }
    default:
      return state
  }
}

export const getRandomId = (): string => {
  // Creates an array of 10 bytes:
  const array = new Uint8Array(10)
  // Fills the array with "cryptographically secure random" values:
  crypto.getRandomValues(array)
  // Converts each random byte to a hexadecimal string and joins them to form a single string:
  return Array.from(array, (byte) => byte.toString(16).padStart(2, '0')).join('')
}
