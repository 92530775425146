import { useState } from 'react'

import { analytics } from '@/core/analytics/events'

export const MAX_FILE_SIZE_MB = 4.5
const MAX_FILE_SIZE_BITS = MAX_FILE_SIZE_MB * 1024 * 1024

const allowedFileTypes = new Set(['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'])

export const useBase64FileConversion = (documentType: string) => {
  const [base64EncodedFile, setBase64EncodedFile] = useState<string | undefined>()
  const [fileName, setFileName] = useState<string | undefined>()
  const [fileSize, setFileSize] = useState<number | undefined>()
  const [objectURL, setObjectURL] = useState<string | undefined>()
  const [fileContentType, setFileContentType] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [hasUploadError, setHasUploadError] = useState(false)

  const onFileInput = (file: File) => {
    setIsLoading(true)
    if (file.size >= MAX_FILE_SIZE_BITS) {
      setIsLoading(false)
      analytics.inAppDocumentUpload.documentUploadFailed({
        documentType,
        reason: `File exceeds ${MAX_FILE_SIZE_MB}mb size limit`,
      })
      return setHasUploadError(true)
    }
    if (!allowedFileTypes.has(file.type)) {
      setIsLoading(false)
      analytics.inAppDocumentUpload.documentUploadFailed({
        documentType,
        reason: `Invalid file type: ${file.type}`,
      })
      return setHasUploadError(true)
    }
    setHasUploadError(false)
    setFileName(file.name)
    setFileSize(file.size)
    setFileContentType(file.type)
    setObjectURL(window.URL.createObjectURL(file))

    const reader = new FileReader()
    reader.onloadend = () => {
      if (!reader.result || typeof reader.result !== 'string') {
        analytics.inAppDocumentUpload.documentUploadFailed({
          documentType,
          reason: `Error reading file`,
        })
        return setHasUploadError(true)
      }

      setBase64EncodedFile(reader.result)
    }
    reader.onerror = () => {
      setHasUploadError(true)
      reader.abort()
    }
    reader.readAsDataURL(file)
    setIsLoading(false)
  }

  const resetFile = () => {
    setBase64EncodedFile(undefined)
    setObjectURL(undefined)
    setFileName(undefined)
    setFileContentType(undefined)
    setIsLoading(false)
    setHasUploadError(false)
  }

  return {
    onFileInput,
    base64EncodedFile,
    objectURL,
    fileName,
    fileSize,
    fileContentType,
    isLoading,
    hasUploadError,
    resetFile,
  } as const
}
