import { GraphQLError } from 'graphql'

export enum BusinessType {
  CCORP = 'CCORP',
  LLC = 'LLC',
  SCORP = 'SCORP',
  PARTNERSHIP = 'PARTNERSHIP',
  SOLEPROP = 'SOLEPROP',
  FREELANCER = 'FREELANCER',
}

export interface IIntercomSettings {
  app_id?: string | undefined
  user_id?: string | undefined | null
  email?: string | undefined
  name?: string | undefined
  alignment?: string
  horizontal_padding?: number
  vertical_padding?: number
  background_color?: string
  action_color?: string
  hide_default_launcher?: boolean
  last_request_at?: number
}

export type IIntercomCallback = () => void

export enum ExpressMail {
  /**
   * USPS First Class (stamp, 3-5 day regular mail)
   */
  REGULAR_MAIL = '1',
  /**
   * Fedex 2 day
   */
  TWO_DAY = '6',
  /**
   * Fedex overnight
   */
  OVERNIGHT = '7',
}

export interface DataLayerArgs {
  event: 'userInfo' | 'businessInfo'
  userEmail?: string
  userFirstName?: string
  userLastName?: string
  userPhoneNumber?: string
  userDOB?: string
  userCompanyName?: string
  userJobTitle?: string
  userGeolocation?: {
    country: string
    city: string
  }
}

declare global {
  interface Window {
    Intercom(update: string, params?: IIntercomSettings | IIntercomCallback): void
    intercomSettings: IIntercomSettings
    mixpanel?: any
    dataLayer?: DataLayerArgs[]
  }
}

declare global {
  const devicer: {
    run: (deviceFPOptions: Record<string, unknown>, cb: (response: { sessionId: string }) => void) => void
  }
}

export interface MutationError extends GraphQLError {
  extensions: GraphQLError['extensions'] & {
    response: {
      body: {
        code: string
        message: string
        success: boolean
      }
      status: number
      statusText: string
      url: string
    }
  }
}

export default {}
