import '@northone/ui-components'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import ContinueButton from '@/components/ContinueButton'
import GoBackButton from '@/components/GoBackButton'
import LoadingScreen from '@/components/LoadingScreen'
import { useFeatureFlag } from '@/core/feature-flags/use-feature-flag'
import { PRIMARY_OWNER_ID } from '@/core/redux/application-redux/application-state'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { BaseContentLayout } from '@/layouts/BaseContentLayout'
import { Pathname } from '@/routes/constants'

import { OwnerProfileForm } from './OwnerProfileForm'
import { useOwnerProfileValidation } from './utils/owner-validation-hooks'

export default function OwnershipOwnerProfilesSoleprop() {
  const t = useOnboardingTranslations()
  const navigate = useNavigate()
  const [shouldShowLoadingScreen, setShouldShowLoadingScreen] = useState(false)
  const isPlaidEnabled = useFeatureFlag('joindot-initial-funding')
  const nextPage = isPlaidEnabled ? Pathname.ACCOUNT_FUNDING : Pathname.APPLICATION_SUBMIT

  const heading = t('ownership.profile.primaryOwnerHeading')
  const loadingTitle = t('ownership.profile.reviewingYourOwnership')

  const { hasError, showErrors, setShowErrors } = useOwnerProfileValidation({
    ownerId: PRIMARY_OWNER_ID,
  })

  const onContinue = () => {
    setShowErrors(true)
    if (hasError) return
    setShouldShowLoadingScreen(true)
    setTimeout(() => {
      navigate(nextPage)
    }, 1_000)
  }
  const onBack = () => {
    navigate(Pathname.OWNERSHIP_DETAILS)
  }

  const primaryButton = <ContinueButton testID="owner-profiles-soleprop-continue" onPress={onContinue} fullWidth />
  const secondaryButton = <GoBackButton testID="owner-profiles-soleprop-back" onPress={onBack} fullWidth />

  if (isPlaidEnabled === undefined) {
    return <LoadingScreen />
  }

  if (shouldShowLoadingScreen) {
    return <LoadingScreen title={loadingTitle} />
  }

  return (
    <BaseContentLayout primaryButton={primaryButton} secondaryButton={secondaryButton} headingText={heading}>
      <OwnerProfileForm ownerId={PRIMARY_OWNER_ID} shouldShowValidationErrors={showErrors} />
    </BaseContentLayout>
  )
}
