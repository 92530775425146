import {
  ActionItem,
  Box,
  EditIcon,
  ICONS,
  IconWithBackground,
  PlusIcon,
  SizeEnum,
  TrashIcon,
} from '@northone/ui-components'
import { useState } from 'react'
import { Pressable } from 'react-native'
import { generatePath, useNavigate } from 'react-router-dom'
import ContinueButton from '@/components/ContinueButton'
import GoBackButton from '@/components/GoBackButton'
import { IOwner } from '@/core/redux/application-redux/application-state'
import { useAppSelector } from '@/core/redux/utils'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { BaseContentLayout } from '@/layouts/BaseContentLayout'
import { Pathname } from '../constants'
import { OwnerIcon } from './owner-profiles/components/owner-icon'
import { DeleteCoOwnerConfirmationModal } from './owner-profiles/DeleteCoOwnerConfirmationModal'

export const OwnershipReviewOwners = () => {
  const t = useOnboardingTranslations()
  const businessName = useAppSelector((state) => state.application.businessName)
  const headingText = t('ownership.review.header', { businessName })
  const subHeadingText = t('ownership.review.subHeader')
  const navigate = useNavigate()
  const navigateBack = () => navigate(Pathname.OWNERSHIP_DETAILS_PRIMARY_OWNER)
  const [ownerIdForDeletion, setOwnerIdForDeletion] = useState<string>('')

  const owners = useAppSelector((state) => state.application.owners)
  const { PRIMARY_OWNER_ID: primaryOwner, ...coOwnersMap } = owners
  const primaryOwnerFullName = primaryOwner ? getOwnerFullName(primaryOwner) : undefined

  const addOwnerActionText = t('ownership.review.addOwner.header')
  const addOwnerActionDescription = t('ownership.review.addOwner.subHeader')

  return (
    <>
      {/* TODO: Create new modal per designs https://northone.atlassian.net/jira/software/c/projects/OBI/boards/174?selectedIssue=OBI-393 */}
      <DeleteCoOwnerConfirmationModal
        ownerId={ownerIdForDeletion}
        closeModal={() => setOwnerIdForDeletion('')}
        isVisible={Boolean(ownerIdForDeletion)}
      />
      <BaseContentLayout
        headingText={headingText}
        subHeadingText={subHeadingText}
        primaryButton={
          <ContinueButton
            fullWidth
            testID="ownership-review-continue-button"
            onPress={() => navigate(Pathname.OWNERSHIP_DETAILS_CONTROLLER)}
          />
        }
        secondaryButton={
          <GoBackButton
            fullWidth
            testID="ownership-review-go-back-button"
            onPress={() => navigate(Pathname.OWNERSHIP_DETAILS_PRIMARY_OWNER)}
          />
        }
      >
        <Box sx={{ gap: '$8' }}>
          <Box sx={{ gap: '$3' }}>
            {primaryOwner && primaryOwnerFullName && (
              <ActionItem
                leftItem={<OwnerIcon owner={primaryOwner} color={'$charcoal1'} />}
                rightItem={<EditIcon size={SizeEnum.MD} />}
                testID="ownership-review-primary-owner-edit"
                action={primaryOwnerFullName}
                onPress={navigateBack}
              />
            )}
            {Object.entries(coOwnersMap).map(([ownerId, coOwner]) => {
              const editOwnerPage = generatePath(Pathname.OWNERSHIP_DETAILS_EDIT_OWNER, { ownerId })
              return (
                <ActionItem
                  key={ownerId}
                  leftItem={<OwnerIcon owner={coOwner} color={'$charcoal1'} />}
                  rightItem={<AddOwnerIcons ownerId={ownerId} setOwnerIdForDeletion={setOwnerIdForDeletion} />}
                  testID="ownership-review-primary-owner-edit"
                  action={getOwnerFullName(coOwner)}
                  onPress={() => navigate(editOwnerPage)}
                />
              )
            })}
          </Box>
          <ActionItem
            testID="ownership-review-add-owner"
            leftItem={
              <IconWithBackground icon={ICONS.AddContactIcon} size={SizeEnum.MD} backgroundColor="$receiveHoneydew" />
            }
            rightItem={<PlusIcon size={SizeEnum.MD} />}
            action={addOwnerActionText}
            description={addOwnerActionDescription}
            onPress={() => navigate(Pathname.OWNERSHIP_DETAILS_ADD_OWNER)}
          />
        </Box>
      </BaseContentLayout>
    </>
  )
}

const AddOwnerIcons = ({
  ownerId,
  setOwnerIdForDeletion,
}: {
  ownerId: string
  setOwnerIdForDeletion: (ownerId: string) => void
}) => (
  <Box sx={{ flexDirection: 'row', gap: '$3' }}>
    <Pressable onPress={() => setOwnerIdForDeletion(ownerId)}>
      <TrashIcon size={SizeEnum.MD} color={'$error4'} />
    </Pressable>
    <EditIcon size={SizeEnum.MD} />
  </Box>
)
const getOwnerFullName = (owner: IOwner) => {
  const firstName = owner.firstName
  const lastName = owner.lastName
  return `${firstName} ${lastName}`
}
