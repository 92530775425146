import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { analytics } from '@/core/analytics/events'
import { fileUploadActions } from '@/core/redux/file-upload-redux/actions'
import { useAppSelector } from '@/core/redux/utils'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { Pathname } from '../constants'
import { FindYourDBAModal } from './components/FindYourDBAModal'
import { DocumentUploadLayout } from './DocumentUploadLayout'
import { useFileUploadNavigationContext } from './file-upload-context/use-file-upload-context'
import { useUploadFileToIntercomConversation } from './intercom'
import { useBase64FileConversion } from './use-base64-file-conversion'

export const DocumentUploadBusinessLicense = () => {
  const navigate = useNavigate()
  const t = useOnboardingTranslations()
  const dispatch = useDispatch()
  const [isFindYourDBAModalVisible, setIsFindYourDBAModalVisible] = useState(false)

  const copy = {
    heading: t('inAppDocumentCapture.businessLicense.heading'),
    subHeading: t('inAppDocumentCapture.businessLicense.subHeading'),
    cannotFind: t('inAppDocumentCapture.businessLicense.cannotFind'),
  }
  const testIDs = {
    continueButton: 'upload-business-license-goback-button',
    backButton: 'upload-business-license-continue-button',
  }

  const {
    base64EncodedFile,
    objectURL,
    isLoading,
    onFileInput,
    fileName,
    fileContentType,
    hasUploadError,
    resetFile,
    fileSize,
  } = useBase64FileConversion('businessLicense')
  const previouslyUploadedFileName = useAppSelector((state) => state.uploadedFileNames.files.businessLicense)
  const { uploadFileToIntercom, uploadFileLoading, uploadFileError } = useUploadFileToIntercomConversation({
    fileName,
    fileContentType,
    fileData: base64EncodedFile,
  })

  const { getFileUploadNavigation } = useFileUploadNavigationContext()
  const { nextPath, prevPath } = getFileUploadNavigation(Pathname.FINISH_UP_FILE_UPLOAD_BUSINESS_LICENSE)

  const continueButtonProps = {
    testID: testIDs.continueButton,
    onPress: () => {
      if (previouslyUploadedFileName) {
        return navigate(nextPath)
      }
      if (!fileName) return
      uploadFileToIntercom?.().then(() => {
        analytics.inAppDocumentUpload.documentUploadSuccess({
          documentType: 'businessLicense',
          fileSize: fileSize ?? 0,
        })
        dispatch(fileUploadActions.setFileName({ fileType: 'businessLicense', name: fileName }))
        navigate(nextPath)
      })
    },
    disabled: !base64EncodedFile && !previouslyUploadedFileName && !fileName,
    loading: uploadFileLoading,
  }

  const backButtonProps = {
    testID: testIDs.backButton,
    onPress: () => navigate(prevPath),
  }

  const handleDeleteFile = () => {
    dispatch(fileUploadActions.deleteFile('businessLicense'))
    resetFile()
  }

  const dismissFindYourDBAModal = () => {
    setIsFindYourDBAModalVisible(false)
    analytics.inAppDocumentUpload.documentUploadSkipped({ documentType: 'businessLicense' })
    navigate(nextPath)
  }

  return (
    <>
      <FindYourDBAModal
        isFindYourDBAModalVisible={isFindYourDBAModalVisible}
        onStillCantFindThesePressed={dismissFindYourDBAModal}
        onUploadButtonPressed={() => setIsFindYourDBAModalVisible(false)}
      />
      <DocumentUploadLayout
        headingText={copy.heading}
        subHeadingText={copy.subHeading}
        viewAcceptedDocsText={copy.cannotFind}
        onPressViewAcceptedDocs={() => setIsFindYourDBAModalVisible(true)}
        continueButtonProps={continueButtonProps}
        backButtonProps={backButtonProps}
        onFileInput={onFileInput}
        onDeleteFile={handleDeleteFile}
        uploadedFileName={previouslyUploadedFileName}
        fileURL={objectURL}
        fileContentType={fileContentType}
        isUploadLoading={isLoading}
        hasUploadError={Boolean(hasUploadError || uploadFileError)}
      />
    </>
  )
}
