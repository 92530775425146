import { Suspense, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import LoadingScreen from './components/LoadingScreen'
import { analytics } from './core/analytics/events'
import { AuthWrapper } from './core/auth/auth-wrapper'
import { LaunchDarklyWrapper } from './core/feature-flags/launchdarkly-wrapper'
import { PersistGate } from './core/persist-state/persist-gate'
import { useAppSelector } from './core/redux/utils'
import { DevHelpers } from './dev-helpers'
import { StepperScreen } from './layouts/StepperLayout'
import { isProduction } from './utils/environment'
import { useAddBusinessIdFieldToAnalytics } from './utils/hooks'

export const App = () => {
  const isPrimaryOwner = useAppSelector((state) => state.application.isPrimaryOwner)
  const { pathname } = useLocation()

  useAddBusinessIdFieldToAnalytics()

  useEffect(() => {
    analytics.page()
  }, [pathname])

  return (
    <AuthWrapper>
      <PersistGate>
        <LaunchDarklyWrapper>
          <StepperScreen isUserPrimaryOwner={isPrimaryOwner ?? true}>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </StepperScreen>
        </LaunchDarklyWrapper>
        {!isProduction() && <DevHelpers />}
      </PersistGate>
    </AuthWrapper>
  )
}

export default App
