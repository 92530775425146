import { Navigate } from 'react-router-dom'

import LoadingScreen from '@/components/LoadingScreen'
import { useFeatureFlag } from '@/core/feature-flags/use-feature-flag'

import { Pathname } from '../constants'

/**
 * Ensures that the children are visible only when the funding feature flag is enabled.
 * Otherwise, redirects to initial routing.
 */
export const RequireFundingEnabled = ({ children }: { children: JSX.Element }) => {
  const isPlaidEnabled = useFeatureFlag('joindot-initial-funding')

  if (isPlaidEnabled === undefined) return <LoadingScreen />
  if (isPlaidEnabled === false) return <Navigate to={Pathname.ROOT} replace />

  return children
}
